<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    border?: boolean
    direction?: string
    imageClass?: string
    openCard?: boolean
    withTransition?: boolean
  }>(),
  {
    border: false,
    direction: 'flex-row',
    imageClass: '',
    openCard: true,
    withTransition: false,
  },
)
defineSlots<{ image(): any; content(): any }>()

const contentWrapper = ref<HTMLDivElement | null>(null)

watch(
  () => props.openCard,
  (val) => {
    calculateContentHeight(val)
  },
)

const calculateContentHeight = (val: boolean) => {
  const content = contentWrapper.value

  if (!val && content) {
    content.style.maxHeight = ''
  } else if (content) {
    content.style.maxHeight = `${content.scrollHeight}px`
  }
}
</script>

<template>
  <div :class="['relative flex', direction]">
    <div v-if="$slots['image']" :class="imageClass ? imageClass : 'flex-1'">
      <slot name="image" />
    </div>
    <div
      v-if="$slots['content']"
      ref="contentWrapper"
      :class="[
        '-translate-y-full contentWrapper flex-1',
        {
          'translate-y-0 opacity-100 isVisible': openCard,
          'border border-gray-200 rounded-sm': border,
          'max-h-0': withTransition,
          'opacity-0 pointer-events-none': !openCard,
        },
      ]"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<style scoped>
.contentWrapper {
  transition:
    transform 0.8s ease,
    max-height 0.8s ease 300ms,
    opacity 0.8s ease;
}

.contentWrapper.isVisible {
  transition:
    transform 0.8s ease,
    max-height 0.8s ease,
    opacity 0.8s ease 300ms;
}
</style>
